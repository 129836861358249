import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img                         from 'gatsby-image';

import { findMedia } from '@interness/web-core/src/components/_helpers';

const HeaderImg = ({ id }) => {
  const { headerImages } = useStaticQuery(query);
  return <Img fluid={findMedia(headerImages.images, id).childImageSharp.fluid}/>
};

export default HeaderImg;

const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 700, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;